import React from "react"
import Container from "../container"
import Title from "../title"
import BackgroundImage from "gatsby-background-image"
import Seperator from "../seperator"
import { StaticQuery, graphql } from "gatsby"
import styles from "./page-header.module.scss"

export default function PageHeader({ title, image, subTitle }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          defaultImage: file(relativePath: { eq: "page-header-default.jpg" }) {
            sharp: childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <BackgroundImage
          Tag="section"
          className={styles.pageHeader}
          fluid={image || data.defaultImage.sharp.fluid}
        >
          <Container>
            <Title headingLevel="h1" className={styles.title}>
              {title}
            </Title>
            <Title headingLevel="h3">{subTitle}</Title>
            <Seperator />
          </Container>
        </BackgroundImage>
      )}
    />
  )
}
