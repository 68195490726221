import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import Container from "../components/container"
import SEO from "../components/seo"
import cn from "classnames"
import styles from "./page.module.scss"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Button from "../components/button"
import { Bullhorn, Clock } from "../components/icons"
import Title from "../components/title"

export default function PageTemplate({ data }) {
  const { mdx } = data
  const { frontmatter, body } = mdx

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <PageHeader
        title={frontmatter.title}
        subTitle={frontmatter.subTitle && frontmatter.subTitle}
        image={frontmatter.image && frontmatter.image.sharp.fluid}
      />
      <Container>
        <div
          className={cn(
            styles.content,
            frontmatter.rightColumn ? styles.contentWithRightColumn : null
          )}
        >
          <div>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
          {frontmatter.rightColumn && (
            <div className={styles.contentBoxes}>
              {frontmatter.rightContentBox.map(item => (
                <div className={styles.contentBox}>
                  <div className={styles.contentBoxIcon}>
                    {item.icon === "clock" ? (
                      <Clock />
                    ) : item.icon === "bullhorn" ? (
                      <Bullhorn />
                    ) : null}
                  </div>
                  <div className={styles.contentBoxContent}>
                    <Title headingLevel="h3">{item.title}</Title>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
              <Button to="/egitim-basvurusu" className={styles.ctaButton}>
                {frontmatter.ctaButtonName}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        image {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subTitle
        rightColumn
        rightContentBox {
          content
          icon
          title
        }
        ctaButtonName
      }
    }
  }
`
